<template>
<div class="score-edit">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="score-edit-cont">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="10px" class="demo-ruleForm">
      <el-form-item label="">
        <p>代号：</p>
        <el-input v-model="codeTranslates[code]" disabled style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="" prop="name">
        <p>任务标题：</p>
        <el-input v-model="ruleForm.name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="" prop="score">
        <p>积分获得数量：</p>
        <el-input v-model="ruleForm.score" type="number" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="" prop="cycle_type">
        <p>任务类型：</p>
        <el-radio-group v-model="ruleForm.cycle_type" @input="changeType">
          <el-radio label="1">每日任务（即多次任务，每日24:00刷新）</el-radio>
          <el-radio label="0">新手任务（即单次任务，仅可做一次的任务)</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="times_limit" v-if="ruleForm.cycle_type == 1">
        <p>执行次数设置：</p>
        <el-radio-group v-model="ruleForm.times_limit"  @input="changeTimes">
          <el-radio label="0">可多次执行，不限次数/天</el-radio>
          <el-radio label="1">可多次执行，设置封顶次数
            <el-input v-model="timesLimit" type="number" style="width: 100px;margin: 0 10px;"></el-input>
            次/天</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="">
        <p>触发方式：</p>
        <el-radio-group v-model="triggerType"  @input="changeTimes">
          <el-radio :label="index" v-for="(item,index) in triggerTypes" :key="item">{{item}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="status">
        <p>是否上架？（若选择是则用户可查看并执行任务，选择否则只保存信息用户无法看到）：</p>
        <el-radio-group v-model="ruleForm.status">
          <el-radio label="0">是</el-radio>
          <el-radio label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>

<!--      <el-form-item>-->
<!--        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
<!--        <el-button @click="resetForm('ruleForm')">重置</el-button>-->
<!--      </el-form-item>-->
    </el-form>
    <div style="padding-top: 20px">
      <Button type="primary" class="mr20" @click="submitForm('ruleForm')">确定</Button>
      <Button @click="cancel">取消</Button>
    </div>


  </div>
</div>
</template>

<script>
export default {
  name: "edit.vue",
  data(){
    const validateTimes = (rule, value, callback) => {
      value = value.toString();
      if (!value) {
        console.log('1111')
        return callback(new Error('请选择执行次数设置'));
      }else{
        console.log(value)
        if(value > 0){
          if(!this.timesLimit){
            return callback(new Error('请输入封顶次数'));
          }else{
            callback();
          }
        }else{
          callback();
        }
      }
    };
    return{
      ruleForm: {
        name: '',
        score:'',
        cycle_type:'',
        times_limit:'',
        status:'',
      },
      rules: {
        name: [
          { required: true, message: '请输入任务标题', trigger: 'blur' },
        ],
        score:[
          { required: true, message: '请输入积分获得数量', trigger: 'blur' },
        ],
        cycle_type:[
          { required: true, message: '请选择任务类型', trigger: 'change' },
        ],
        status:[
          { required: true, message: '请选择是否上架', trigger: 'change' },
        ],
        times_limit:[
          { validator: validateTimes, trigger: 'change'  },
        ]
      },
      timesLimit:'',
      dataId:'',
      codeTranslates:{},
      code:'',
      triggerTypes:{},
      triggerType:0
    }
  },
  created(){
    this.dataId = this.$route.query.id || '';
    this.getDetail();
  },
  methods:{
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            id:this.dataId,
            name:this.ruleForm.name,
            score:this.ruleForm.score,
            status:this.ruleForm.status,
            cycle_type:this.ruleForm.cycle_type,
            trigger_type:this.triggerType,
          }
          if(this.ruleForm.times_limit > 0){
            params.times_limit = this.timesLimit;
          }else{
            params.times_limit = this.ruleForm.times_limit;
          }
          this.api.score.taskUpdate(params).then((res)=>{
            this.$Message.success('操作成功');
            this.$router.go(-1);
          })
        }
      });
    },
    cancel(){
      this.$router.go(-1);
    },
    changeTimes(){
      if(this.ruleForm.times_limit){
        if(this.ruleForm.times_limit > 0){
          this.timesLimit = 1;
        }else{
          this.timesLimit = '';
        }
      }
    },
    changeType(){
      this.ruleForm.times_limit = '';
      this.timesLimit = '';
    },
    getDetail(){
      let params = {
        id:this.dataId
      };
      this.api.score.taskDetail(params).then((res)=>{
        this.code = res.info.code;
        this.ruleForm.name = res.info.name;
        this.ruleForm.score = res.info.score;
        this.ruleForm.cycle_type = res.info.cycle_type;
        this.ruleForm.status = res.info.status;
        if(res.info.times_limit > 0){
          this.ruleForm.times_limit = '1';
          this.timesLimit = res.info.times_limit;
        }else{
          this.ruleForm.times_limit = res.info.times_limit;
        }
        this.triggerType = res.info.trigger_type;
        this.codeTranslates = res.codeTranslates;
        this.triggerTypes = res.triggerTypes;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.score-edit{
  margin: 20px;
  font-size: 14px;
  .score-edit-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
  }
}
</style>
